import { Component } from '@angular/core';

@Component({
    selector: 'home-svg',
    template: ` <svg
    class="home-svg"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 60 60"
    xml:space="preserve"
  >
    <style type="text/css">
      .st1 {
        stroke: #ffffff;
      }
    </style>

    <g>
      <polygon
        class="st1"
        points="29.8,7 21.5,15.4 21.5,12 14.8,12 14.8,22.1 9,28 10.2,29.2 29.8,9.4 49.3,29.2
            50.5,28"
      />
      <polygon
        class="st1"
        points="14.8,28.8 14.8,48.9 26.4,48.9 26.4,37.2 33.1,37.2 33.1,48.9 44.7,48.9 44.7,28.8
            29.8,13.7"
      />
    </g>
  </svg>`,
    standalone: true,
})
export class HomeSvgComponent {}
