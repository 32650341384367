import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@services/translate.service';
import { AuthService } from '@services/auth.service';
import { Store } from '@ngrx/store';
import AppState from '@state/app.state';
import { AuthLogoutAction } from '@state/actions/auth.actions';
import { PropertyService } from '@services/property.service';
import { TranslatePipe } from '../../helpers/translate.pipe';
import { LangSelectorComponent } from '../lang-selector/lang-selector.component';
import { LogoutSvgComponent } from '../../svg/logout-svg.component';
import { RealEstateSvgComponent } from '../../svg/realestate-svg.component';
import { ListSvgComponent } from '../../svg/list-svg.component';
import { AddSvgComponent } from '../../svg/add-svg.component';
import { UserSvgComponent } from '../../svg/user-svg.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { ValueIconComponent } from '../../svg/value-icon.component';
import { MapIconComponent } from '../../svg/map-icon.component';
import { SearchComponent } from '../../svg/search.component';
import { HomeSvgComponent } from '../../svg/home-svg.component';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { TrackClickDirective } from '../../helpers/track-click.directive';

@Component({
    selector: 'app-hamburger-menu',
    templateUrl: './hamburger-menu.component.html',
    styleUrls: ['./hamburger-menu.component.scss'],
    standalone: true,
    imports: [
        TrackClickDirective,
        RouterLinkActive,
        RouterLink,
        HomeSvgComponent,
        SearchComponent,
        MapIconComponent,
        ValueIconComponent,
        NgIf,
        UserSvgComponent,
        AddSvgComponent,
        ListSvgComponent,
        RealEstateSvgComponent,
        LogoutSvgComponent,
        LangSelectorComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class HamburgerMenuComponent implements OnInit {
  propsByStatus = {
    draft: 0,
    pending: 0,
    published: 0,
  };

  isAuthed = false;
  constructor(
    public trans: TranslateService,
    public auth: AuthService,
    private store: Store<AppState>,
    private propService: PropertyService
  ) {}

  ngOnInit(): void {
    this.auth.isAuthenticated().subscribe((isAuth) => {
      this.isAuthed = isAuth;
      if (isAuth) {
        this.propService.countUserPropertiesByStatus().subscribe((res) => Object.assign(this.propsByStatus, res));
      }
    });
  }

  toggleHamMenu(value: boolean = undefined) {
    this.trans.toggleHamMenu(value);
  }

  logout() {
    this.store.dispatch(AuthLogoutAction());
  }
}
