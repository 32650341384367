import {
  AuthStartAction,
  AuthLogoutAction,
  AuthSuccessAction,
  AuthFailedAction,
  AuthStartLoginAsAction,
} from '@state/actions/auth.actions';
import { createReducer, on } from '@ngrx/store';
import { initialState } from '@state/app.state';

export const authReducer = createReducer(
  initialState.auth,
  on(AuthStartAction, (state, payload) => ({
    token: '',
    user: payload.user,
    sending: true,
    error: null,
    errorCode: null,
    authorizations: {
      ADMIN_ACCESS: false,
    },
  })),
  on(AuthStartLoginAsAction, (state, { email }) => ({
    ...state,
    user: { email },
    sending: true,
  })),
  on(AuthLogoutAction, () => ({
    sending: false,
    token: '',
    user: { email: null },
    error: null,
    errorCode: null,
    authorizations: {
      ADMIN_ACCESS: false,
    },
  })),
  on(AuthSuccessAction, (state, payload) => ({
    token: payload.auth.token,
    sending: false,
    user: { email: state.user.email },
    error: null,
    errorCode: null,
    authorizations: payload.auth.authorizations,
  })),
  on(AuthFailedAction, (state, payload) => ({
    ...payload,
    token: '',
    sending: false,
    user: { email: state.user.email },
    authorizations: {
      ADMIN_ACCESS: false,
    },
  })),
);
