<h2 class="header-2">{{ 'Features Properties' | _: trans.lang }}</h2>

<div class="featured-properties">
    <a *ngFor="let prop of results.properties; let idx = index" class="property-card" [routerLink]="generateAdUrl(prop)" trackClick="click_featured"
        [trackPropertyId]="prop.id">
        <div class="clip-star" title="{{'Featured property' | _: trans.lang }}">★</div>
        <div class="property-ribon">
            Premium
        </div>

        <img src="{{getAssetUrl(prop)}}" alt="{{ 'Photo of' | _: trans.lang }} {{getShortDesc(prop)}}">
        <div class="card-body">
            <property-price [propertyData]="prop"></property-price>
            <p title="{{ 'Address' | _: trans.lang }}: {{buildAdress(prop)}}" class="normal-text-light no-wrap-text"> {{ buildAdress(prop) }}</p>
            <p class="no-wrap-text"><span>{{getPropCategory(prop)}}</span></p>
        </div>
    </a>
</div>