<h2 class="header-2">{{ title | _: trans.lang }}</h2>
<p>{{ subtitle | _: trans.lang }}</p>
<div class="popular-container">
  <div class="popular-area" *ngFor="let dataList of popularAreas$ | async">
    <h3><a routerLink="/properties/{{contractType}}/{{ dataList.city | cleanAccent | lowercase }}">{{ dataList.city }}</a></h3>
    <nav>
      <a
        *ngFor="let addr of dataList.addresses"
        class="normal__link"
        routerLink="/properties/{{contractType}}/address-{{ addr.address | lowercase }}-{{ dataList.city | cleanAccent | lowercase }}"
        >{{ addr.address }} ({{ addr.total }})
      </a>
    </nav>
  </div>
</div>
