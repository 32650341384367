import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { buildGetSettings, buildGetSettingsError, SETTINGS_SET_LANGUAGE } from '@state/actions/settings.actions';
import AppAction from '@state/actions';
import { of, timer } from 'rxjs';
import { AuthService } from '@services/auth.service';

const SETTINGS_POLLING_3MIN = 3 * 60 * 1000;
@Injectable()
export class SettingsEffects {
  constructor(private actions$: Actions, @Inject(DOCUMENT) private doc: any, private auth: AuthService) {}

  // pollSettings$ = createEffect(() =>
  //   timer(0, SETTINGS_POLLING_3MIN).pipe(
  //     switchMap(() =>
  //       this.auth.getSettings().pipe(
  //         map((results) => buildGetSettings(results)),
  //         catchError(() => of(buildGetSettingsError()))
  //       )
  //     )
  //   )
  // );

  setLanguage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SETTINGS_SET_LANGUAGE),
        tap((action: AppAction) => {
          this.doc.documentElement.setAttribute('lang', action.payload);
        })
      );
    },
    { dispatch: false }
  );
}
