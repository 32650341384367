import AppAction from '@state/actions';

export const MESSAGE_LOAD = 'MESSAGE_LOAD';
export const MESSAGE_LOADED = 'MESSAGE_LOADED';
export const MESSAGE_MARK_READ = 'MESSAGE_MARK_READ';
export const MESSAGE_REPLY = 'MESSAGE_REPLY';
export const MESSAGE_DELETE = 'MESSAGE_DELETE';

export const buildMessagesLoad = () => {
  return new AppAction(MESSAGE_LOAD, null);
};

export const buildMessagesLoaded = list => {
  return new AppAction(MESSAGE_LOADED, list);
};

export const buildMessageMarkRead = id => {
  return new AppAction(MESSAGE_MARK_READ, id);
};

export const buildMessageReply = msg => {
  return new AppAction(MESSAGE_REPLY, msg);
};

export const buildMessageDelete = id => {
  return new AppAction(MESSAGE_DELETE, id);
};
