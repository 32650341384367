import {
  SAVE_PROPERTY_START,
  SAVE_PROPERTY_SUCCESS,
  SAVE_PROPERTY_FAIL,
  DELETE_SELECTED_PROPERTIES_DONE,
  DELETE_SELECTED_PROPERTIES_FAIL,
  DELETE_SELECTED_PROPERTIES,
  DELETE_PROPERTY_ASSET,
  LOAD_USER_PROPERTIES_START,
  LOAD_USER_PROPERTIES_DONE,
  EDIT_PROPERTY,
  LOAD_PROPERTY_START,
  SET_PROPERTIES_STATUS,
  SET_PROPERTIES_STATUS_DONE,
  SET_PROPERTIES_STATUS_FAILED,
  RESTORE_USER_PROPERTIES_DONE,
  RESTORE_USER_PROPERTIES,
  LOAD_PROPERTY_SUCCESS,
  GEO_DECODE_PROPERTY_ADDRESS,
} from '@state/actions/user-properties.actions';
import AppAction from '@state/actions';
import PropertyInterface from '@interfaces/property.interface';
import { UserProperties } from '@state/app.state';

export function userPropertiesReducer(state: UserProperties, action: AppAction) {
  switch (action.type) {
    // list of properties
    case LOAD_USER_PROPERTIES_START:
      return { ...state, params: action.payload, sending: true };

    case LOAD_USER_PROPERTIES_DONE:
      const { properties, total, error } = action.payload;
      return { ...state, properties, total, error, sending: false };

    // load property
    case LOAD_PROPERTY_START: {
      return { ...state, editProperty: null, sending: true };
    }
    case LOAD_PROPERTY_SUCCESS: {
      return { ...state, editProperty: action.payload, sending: false };
    }
    case GEO_DECODE_PROPERTY_ADDRESS: {
      return { ...state, editProperty: {...state.editProperty, ...action.payload }, sending: false };
    }

    // edit-save property
    case EDIT_PROPERTY: {
      const { property } = action.payload;
      return {
        ...state,
        editProperty: {
          ...state.editProperty,
          ...property,
        },
      };
    }

    case SAVE_PROPERTY_START: {
      return {
        ...state,
        sending: true,
        closeOnSave: action.payload.closeOnSave,
      };
    }

    case SAVE_PROPERTY_SUCCESS: {
      const { id } = action.payload;
      const editProperty = { ...state.editProperty, id };

      return { ...state, editProperty, sending: false };
    }
    case SAVE_PROPERTY_FAIL:
      return { ...state, error: action.payload, sending: false };
    case DELETE_SELECTED_PROPERTIES: {
      const ids = action.payload;
      const properties = JSON.parse(JSON.stringify(state.properties));
      properties.forEach((p: PropertyInterface) => (ids.indexOf(p.id) > -1 ? (p.deleted = 1) : null));

      return { ...state, properties, sending: true };
    }

    //  multiple properties actions
    case DELETE_SELECTED_PROPERTIES_DONE:
    case DELETE_SELECTED_PROPERTIES_FAIL:
      return {
        ...state,
        sending: false,
      };
    case DELETE_PROPERTY_ASSET: {
      const { asset, propertyIndex } = action.payload;
      const properties = [...state.properties];
      const property = properties[propertyIndex];
      property.assets = property.assets.filter((a) => asset.id !== a.id);
      return { ...state, properties };
    }

    case SET_PROPERTIES_STATUS: {
      const { ids, status } = action.payload;
      const properties = JSON.parse(JSON.stringify(state.properties));
      properties.forEach((p: PropertyInterface) => (ids.indexOf(p.id) > -1 ? (p.status = status) : null));

      return { ...state, properties, sending: true };
    }

    case SET_PROPERTIES_STATUS_FAILED:
    case SET_PROPERTIES_STATUS_DONE: {
      return { ...state, sending: false };
    }
    case RESTORE_USER_PROPERTIES: {
      const ids = action.payload;
      const properties = JSON.parse(JSON.stringify(state.properties));
      properties.forEach((p: PropertyInterface) => (ids.indexOf(p.id) > -1 ? (p.deleted = 0) : null));

      return { ...state, properties, sending: true };
    }
    case RESTORE_USER_PROPERTIES_DONE: {
      return { ...state, sending: false };
    }
    default:
      return state;
  }
}
