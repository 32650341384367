import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { TranslateService } from '@services/translate.service';
import { Authorizations } from '@state/app.state';
import { TrackClickDirective } from 'app/helpers/track-click.directive';
import { TranslatePipe } from 'app/helpers/translate.pipe';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
  standalone: true,
  imports:[NgIf, RouterModule, TranslatePipe, AsyncPipe, TrackClickDirective]
})
export class HeaderDesktopComponent {
  protected destroyed$: Subject<boolean> = new Subject();
  isAuth$: Observable<boolean>;
  authorizations$: Observable<Authorizations>;
  isAdmin: boolean = false;

  constructor(public trans: TranslateService, private auth: AuthService) {
    this.isAuth$ = this.auth.isAuthenticated();
    this.auth.auths().subscribe((auths) => {
      this.isAdmin = auths.ADMIN_ACCESS;
    });
  }
}
