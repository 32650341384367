<header class="header">
  <nav class="nav main-nav" aria-label="Main website navigation menu" role="navigation"
    itemtype="https://schema.org/SiteNavigationElement">
    <ul class="nav-items">
      <li class="nav-item" routerLinkActive="selected" [routerLinkActiveOptions]="{ exact: true }">
        <a trackClick="click_home_mob" routerLink="/" class="home main-nav__link" aria-label="Home page">
          <home-svg></home-svg>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="selected">
        <a trackClick="click_search_mob" routerLink="/search/filter" class="search main-nav__link" aria-label="Search page"> 
          <search-svg></search-svg>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="selected">
        <a trackClick="click_map_mob" routerLink="/map" class="map main-nav__link" aria-label="Properties map">
          <map-icon-svg></map-icon-svg>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="selected">
        <a trackClick="click_account_mob" routerLink="/account" class="user main-nav__link" aria-label="Account">
          <user-svg></user-svg>
        </a>
      </li>
      <li class="nav-item">
        
      </li>
    </ul>
  </nav>
</header>
