import { createReducer, on } from '@ngrx/store';
import { CreateSubscriptionLink, EndSubscriptionRequest, StartSubscription } from '@state/actions/subscription.actions';
import { initialState } from '@state/app.state';

export const subscriptionReducer = createReducer(
  initialState.subscription,
  on(StartSubscription, (state, { packageType }) => ({ ...state, status: 'init', selectedPackage: packageType })),
  on(CreateSubscriptionLink, (state) => ({ ...state, status: 'stripe-redirect', syncing: true})),
  on(EndSubscriptionRequest, (state, { status, error }) => ({ ...state, status, error, syncing: false}))
);
