import { ApplicationConfig, APP_ID } from '@angular/core';
import { InMemoryScrollingFeature, provideRouter, withInMemoryScrolling } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';

import { RequestInterceptor, ResponseErrorInterceptor } from 'app/interceptors/request.interceptor';
import { CacheRouteReuseStrategy } from './cache-route-reuse.strategy';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideStore } from '@ngrx/store';
import { reducers } from '@state/reducers';
import { loadStateFromLocalStorage } from './helpers';
import { provideEffects } from '@ngrx/effects';

import { SettingsEffects } from '@state/effets/settings.effects';
import { UserAccountEffects } from '@state/effets/user-account.effects';
import { SearchEffects } from '@state/effets/search.effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { environment } from 'environments/environment';
import { provideAnimations } from '@angular/platform-browser/animations';
import { SubscriptionEffects } from '@state/effets/subscription.effects';

const inMemoryScrollingFeature: InMemoryScrollingFeature =  withInMemoryScrolling({
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
});
export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes, inMemoryScrollingFeature),
    provideStore(reducers, {
      initialState: loadStateFromLocalStorage,
    }),
    provideEffects([SearchEffects, SettingsEffects, UserAccountEffects, SubscriptionEffects]),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75
    }),
    provideClientHydration(),
    provideHttpClient(
      // withFetch(),
      withInterceptorsFromDi()
    ),
    { provide: APP_ID, useValue: 'homezone' },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: BrowserStateInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseErrorInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CacheInterceptor,
    //   multi: true,
    // },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy,
    },
    // {
    //   provide: IMAGE_LOADER,
    //   useValue: (config: ImageLoaderConfig) => {
    //     return `/assets/images/no-image.png`;
    //   },
    // },
  ],
};
