import {
  UPLOAD_ADD_FILE,
  UPLOAD_FAILED,
  UPLOAD_SUCCESS,
  UPLOAD_START
} from '@state/actions/uploader.actions';
import AppAction from '@state/actions';
import { Uploader } from '@state/app.state';
import { UPLOADER_UPLOADING, UPLOADER_DONE } from '@services/upload.service';

export function uploaderReducer(state: Uploader, action: AppAction) {
  switch (action.type) {
    case UPLOAD_ADD_FILE:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.index]: action.payload
        }
      };
    case UPLOAD_START:
      const startFile = Object.assign({}, state.files[action.payload.index], {
        status: UPLOADER_UPLOADING
      });
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.index]: startFile
        }
      };
    case UPLOAD_SUCCESS:
    case UPLOAD_FAILED:
      const finishFile = { ...state.files[action.payload.index], ...action.payload };
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.index]: finishFile
        }
      };
    default:
      return state;
  }
}
