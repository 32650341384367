<div class="page-hero page-hero__limited">
  <section class="page-hero-title home-title">
    <h1 class="header-1">{{ 'home.title1' | _: trans.lang }}</h1>
    <p>{{ 'home.subtitle' | _: trans.lang }}</p>
  </section>

  <section class="homepage-search-input" (mousedown)="loadLocations()">
    <div class="homepage-search-links">
      <span (click)="setSearchContractType('sale')" [ngClass]="{ selected: searchType == 'sale' }" class="link-home">
        {{ 'Buy' | _: trans.lang }}
      </span>
      <span (click)="setSearchContractType('rent')" [ngClass]="{ selected: searchType == 'rent' }" class="link-home">
        {{ 'Rent' | _: trans.lang }}
      </span>
    </div>
    <xselect (selectedOptionsChange)="locationChange($event)" [options]="locations" placeholderText="{{ 'Enter city, county or area' | _: trans.lang }}">
    </xselect>
  </section>
</div>

<!-- Diaspora banner -->
<!-- <div class="page-section banner shadow-1 diaspora-banner" *ngIf="!showStatsBanner">
  <p class="header-1 header-1--small val-header-1">{{ 'banner2.text1' | _: trans.lang }}</p>
  <p class="header-1 header-1--small val-header-2">{{ 'banner2.text2' | _: trans.lang }}</p>
  <img class="val-logo" src="/assets/images/ext/diaspora_logo.webp" alt="Logo Disaposora of Albania" />
  <a trackClick="click_diasp_banner1" href="https://www.diasporaofalbania.com/?ref=homezone" target="_blank" class="button button--secondary val-btn">{{
    'Join us' | _: trans.lang
    }}</a>
</div> -->
<!-- //Diaspora banner -->


<div class="page-section">
  <h2 class="header-2">{{ 'home.category.title' | _: trans.lang }}</h2>

  <div class="home-categories" trackClick="click_categories_home">
    <a class="box-home shadow-1" routerLink="/properties/sale">
      <img src="/assets/images/home/apartment-search.webp" alt="{{ 'Search apartaments for sale' | _: trans.lang }}" />
      <h3>{{ 'Apartments for sale' | _: trans.lang }}</h3>
      <p>{{ 'Search for the latest apartments all over Albania' | _: trans.lang }}</p>
    </a>

    <a class="box-home shadow-1" routerLink="/properties/rent">
      <img src="/assets/images/home/house-search.webp" alt="{{ 'Search houses for rent' | _: trans.lang }}" />
      <h3>{{ 'House for rent' | _: trans.lang }}</h3>
      <p>{{ 'Looking for modern villas and private houses' | _: trans.lang }}</p>
    </a>
    <!-- <a class="box-home shadow-1" routerLink="/properties/sale" [queryParams]="{ category: 'commercial' }">
      <img src="/assets/images/home/commercial-search.webp" alt="{{ 'Shops for sale' | _: trans.lang }}" />
      <h3>{{ 'Shops for sale' | _: trans.lang }}</h3>
      <p>{{ 'Looking to find the right spot for your bussines' | _: trans.lang }}</p>
    </a> -->
    <a class="box-home shadow-1 desktop-only" routerLink="/properties/sale" [queryParams]="{ category: 'land' }">
      <img src="/assets/images/home/land-search.webp" alt="{{ 'Search lands for sale' | _: trans.lang }}" />
      <h3>{{ 'Land for sale' | _: trans.lang }}</h3>
      <p>{{ 'Build your dream home on plot of land near the sea' | _: trans.lang }}</p>
    </a>
    <!-- <a class="box-home shadow-1" routerLink="/properties/rent">
      <img src="/assets/images/home/rent-search.webp" alt="{{ 'Properties for rent' | _: trans.lang }}" />
      <h3>{{ 'Properties for rent' | _: trans.lang }}</h3>
      <p>{{ 'Explore thousand of options of houses to rent, and many other properties' | _: trans.lang }}</p>
    </a> -->
  </div>
</div>

<div class="page-section section-min-height">
  <featured-properties></featured-properties>
</div>

<div class="page-section section-min-height" trackClick="click_recent_home" data-testid="recent-props" #recentprops>
  @defer (on viewport(recentprops)) {
    <recent-properties ngSkipHydration></recent-properties>
  }
</div>


<!-- Price report section -->
<div class="page-section banner shadow-1 stat-banner"> 
  <h2 class="header-2">{{ 'Price report' | _: trans.lang }} {{statsValues.yearNow}}</h2>

  <div class="stat-wrapper" *ngIf="statsValues.mediumPriceYearNow">
    <div class="stat-box">
      <h3>{{statsValues.mediumPriceYearNow | currency: 'EUR' : 'symbol': '1.0-0' }} m<sup>2</sup></h3>
      <p>{{ 'Average national sale price' | _: trans.lang }}</p>
    </div>

    <div class="stat-box">
      <h3>{{statsValues.mediumPriceYearNowCapital | currency: 'EUR' : 'symbol': '1.0-0'}} m<sup>2</sup></h3>
      <p>{{ 'Average sale price in Tirana' | _: trans.lang }}</p>
    </div>

    <div class="stat-box">
      <h3>{{statsValues.percentChange > 0 ? '+': '-'}}{{ statsValues.percentChange }}%</h3>
      <p>{{ 'Sale price rise nationally compared to' | _: trans.lang }} {{ statsValues.yearPrev }}</p>
    </div>
  </div>

  <img class="val-logo" src="/assets/images/logo/homezone_logo_white.svg" alt="Homezone.al logo" />
  <a trackClick="click_view_report_home" routerLink="/report/stats" class="button button--secondary val-btn">{{ 'View report' | _: trans.lang }}</a>
</div>
<!-- //Price report section -->

<div class="page-section" trackClick="click_pop_section_rent_home">
  <popular-areas></popular-areas>
</div>
<div class="page-section home-popular" trackClick="click_pop_section_home">
  <popular-areas contractType="rent"></popular-areas>
</div>

<div class="page-section banner shadow-1 val-banner" *ngIf="featureValuation$ | async">
  <p class="header-1 header-1--small val-header-1">{{ 'banner.text1' | _: trans.lang }}</p>
  <p class="header-1 header-1--small val-header-2">{{ 'banner.text2' | _: trans.lang }}</p>
  <img class="val-logo" src="/assets/images/logo/homezone_logo_white.svg" alt="Logo Homezone" />
  <a trackClick="click_prop_value_home" routerLink="/report/value" class="button button--secondary val-btn">{{
    'Evaluate property' | _: trans.lang
    }}</a>
</div>

<div class="page-section premium-angecy" trackClick="click_premium_home" #premiumagencies>
  @defer (on viewport(premiumagencies)) {
    <premium-agencies ></premium-agencies>
  }
</div>

<article class="page-section blog-link-container">
  <h3>{{ 'Our blog with advices' | _: trans.lang }}</h3>
  <nav>
    <ul>
      <li *ngFor="let bb of blogs; let i = index">
        <a routerLink="/{{ bb.routerLink }}" class="normal__link">
          {{ bb.title | _: trans.lang }}
        </a>
      </li>
    </ul>
  </nav>
</article>
