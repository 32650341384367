import AppAction from '@state/actions';
export const SETTINGS_SET_LANGUAGE = 'SETTINGS_SET_LANGUAGE';
export const SETTINGS_GET = 'SETTINGS_GET';
export const SETTINGS_GET_ERR = 'SETTINGS_GET_ERR';

export const buildSetLanguage = lang => {
  return new AppAction(SETTINGS_SET_LANGUAGE, lang);
};

export const buildGetSettings = settings => {
  return new AppAction(SETTINGS_GET, settings);
};
export const buildGetSettingsError = () => {
  return new AppAction(SETTINGS_GET_ERR, null);
};