import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PropertyPriceComponent } from '@components/property-price/property-price.component';
import { IMAGE_SIZE_MOBILE, NO_IMAGE_URL } from '@constants/index';
import PropertyInterface from '@interfaces/property.interface';
import SearchResultsInterface from '@interfaces/search-result.interface';
import { SearchService } from '@services/search.service';
import { TranslateService } from '@services/translate.service';
import { generateAdUrl, getAssetUrlForSize, getPropertyCategory, getPropertyShortDesc } from 'app/helpers';
import { getFormattedAddress } from 'app/helpers/address-helpers';
import { TrackClickDirective } from 'app/helpers/track-click.directive';
import { TranslatePipe } from 'app/helpers/translate.pipe';

@Component({
  selector: 'featured-properties',
  templateUrl: './featured-properties.component.html',
  styleUrls: ['./featured-properties.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, TranslatePipe, TrackClickDirective, PropertyPriceComponent],
})
export class FeaturedPropertiesComponent {
  results: SearchResultsInterface = {
    properties: [],
    total: 0,
    agencies: {},
    geojson: {},
  };
  largeAddress: string = '';

  constructor(public trans: TranslateService, private searchService: SearchService) {}

  ngOnInit(): void {
    this.searchFeatured();
  }

  buildAdress(property: PropertyInterface) {
    return getFormattedAddress(property, true, true);
  }
  
  generateAdUrl(property: PropertyInterface) {
    return generateAdUrl('', property);
  }

  getAssetUrl(property: PropertyInterface) {
    if (!property.assets || !property.assets.length) return NO_IMAGE_URL;

    return getAssetUrlForSize(IMAGE_SIZE_MOBILE, property.assets[0].path);
  }

  getShortDesc(property: PropertyInterface) {
    return getPropertyShortDesc(property, this.trans);
  }

  getPropCategory(property: PropertyInterface) {
    return getPropertyCategory(property, this.trans);
  }


  private searchFeatured() {
    this.searchService.searchFeatured().subscribe({
      next: (response: any) => {
        this.results = response;
      },
    });
  }
}
