<svg-container></svg-container>

<ng-container *ngIf="!(offlineMode$ | async)">
  <header-mobile class="mobile-only"></header-mobile>
  <header-desktop class="desktop-only"></header-desktop>
  <div class="ham-menu-toggle">
    <app-hamburger-menu></app-hamburger-menu>
  </div>

  <div class="body">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
  <app-footer></app-footer>
</ng-container>

<div class="offline" *ngIf="offlineMode$ | async">
  <img src="/assets/images/logo/homezone_logo_white.svg" />
  <h1>OFFLINE</h1>
  <h2>Homezone is in maintenance. We are working on important updates.</h2>
  <p class="center-flex">
    <a class="social-media facebook" href="https://www.facebook.com/homezone.al" target="_blank"></a>
    <a class="social-media instagram" href="https://www.instagram.com/homezone.al/" target="_blank"></a>
  </p>
</div>

<pre class="debug" *ngIf="debugData">
  {{ debugData | json }}
</pre>

@if(loader.isLoading()) {
<div class="spinner-wrapper">
  <span class="h-loader"></span>
</div>
}