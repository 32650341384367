import { Routes, ExtraOptions } from '@angular/router';
import { NotFoundComponent } from '@components/not-found/not-found.component';
import { HomeComponent } from './modules/home/home.component';

const loadSearch = () => import('./modules/search/search.routes')

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'search', loadChildren: loadSearch },
  { path: 'properties', loadChildren: loadSearch },
  { path: 'prona', loadChildren: loadSearch },
  { path: 'shtepi', loadChildren: loadSearch },
  { path: 'apartamente', loadChildren: loadSearch },
  { path: 'toke', loadChildren: loadSearch },
  { path: 'komercial', loadChildren: loadSearch },

  { path: 'agent', loadChildren: () => import('./modules/agent/agent.routes') },
  { path: 'map', loadChildren: () => import('./modules/map/map.routes') },
  { path: 'account', loadChildren: () => import('./modules/account/account.routes') },
  { path: 'blog', loadChildren: () => import('./modules/blog/blog.routes') },
  { path: 'legal',  loadChildren: () => import('./modules/legal/legal.routes') },
  { path: 'help', loadChildren: () => import('./modules/help/help.routes') },
  { path: 'property', loadChildren: () => import('./modules/property/property.routes') },
  { path: 'report', loadChildren: () => import('./modules/report/report.routes') },
  { path: 'settings', loadChildren: () => import('./modules/settings/settings.routes') },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.routes') },
  { path: 'subscribe', loadChildren: () => import('./modules/subscribe/subscribe.routes') },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' },
];
