import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

export class CacheRouteReuseStrategy implements RouteReuseStrategy {
  storedRouteHandles = new Map<string, DetachedRouteHandle>();
  routeScroll = {};

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.data.reuseRoute && curr.data.reuseRoute) {
      return true;
    }
    return future.routeConfig === curr.routeConfig;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return route.data.reuseRoute && !!this.storedRouteHandles.get(route.data.reuseRoute);
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const path = route.data.reuseRoute;
    const scrollTo = this.routeScroll[path] || 0;
    const cmp = this.storedRouteHandles.get(path);
    if ((cmp as any).componentRef.instance.refreshSeoTags) {
      (cmp as any).componentRef.instance.refreshSeoTags();
    }
    setTimeout(() => window.scrollTo(0, scrollTo), 1);

    return cmp as DetachedRouteHandle;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data.reuseRoute;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const path = route.data.reuseRoute;
    this.routeScroll[path] = window.pageYOffset;
    this.storedRouteHandles.set(path, handle);
  }
}
