import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from '@services/auth.service';
import { Store, createSelector } from '@ngrx/store';
import AppState, { UserAccount } from '@state/app.state';
// import { MessagesService } from '@services/messages.service';
import { TranslateService } from '@services/translate.service';
import { SeoService } from '@services/seo.service';
import { HomePageSeo } from 'app/seo/HomePageSeo';
import { SearchService } from '@services/search.service';
import SearchParamsParser from '@model/SearchParamsParser';
import LocationInterface from '@interfaces/LocationInterface';
import SearchParamsInterface from '@interfaces/search-params.interface';

import { BLOG_LIST, BlogInterface } from '@constants/blogs';
import { LOCATION_COUNTY } from '@constants/enum';
import { Observable, of } from 'rxjs';
import { AppsService } from '@services/apps.service';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from 'app/helpers/translate.pipe';
import { XselectComponent } from '@components/xselect/xselect.component';
import { FeaturedPropertiesComponent } from './featured-properties/featured-properties.component';
import { RecentPropertiesComponent } from './recent-properties/recent-properties.component';
import { PopularAreasComponent } from './popular-areas/popular-areas.component';
import { PremiumAgenciesComponent } from './premium-agencies/premium-agencies.component';
import { TrackClickDirective } from 'app/helpers/track-click.directive';

interface StatsInterface {
  mediumPriceYearNow: string;
  mediumPriceYearPrev: string;
  mediumPriceYearNowCapital: string;
  percentChange: string;
  yearNow: string;
  yearPrev: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslatePipe,
    XselectComponent,
    FeaturedPropertiesComponent,
    RecentPropertiesComponent,
    PopularAreasComponent,
    PremiumAgenciesComponent,
    TrackClickDirective,
  ],
})
export class HomeComponent implements OnInit {
  blogs: BlogInterface[] = BLOG_LIST;
  preLoadLocations = [
    { type: 'city', value: 'Tiran\u00eb', count: 19450 },
    { type: 'city', value: 'Durr\u00ebs', count: 5378 },
    { type: 'city', value: 'Vlor\u00eb', count: 1164 },
    { type: 'city', value: 'Sarand\u00eb', count: 344 },
    { type: 'city', value: 'Shkod\u00ebr', count: 330 },
    { type: 'city', value: 'Kavaj\u00eb', count: 160 },
    { type: 'city', value: 'Sh\u00ebngjin', count: 84 },
  ];
  locations: Array<LocationInterface> = [];
  locationLoaded: boolean = false;

  isAuth: boolean = false;
  user;
  searchType = 'sale';
  countMessages = 0;
  featureValuation$: Observable<boolean> = of(true); // default is enabled
  featureStats$: Observable<boolean> = of(true); // default is enabled
  flagFeaturedProps$: Observable<boolean> = of(true); // default is enabled
  statsValues = {
    mediumPriceYearNow: null,
    mediumPriceYearPrev: null,
    mediumPriceYearNowCapital: null,
    percentChange: null,
    yearNow: null,
    yearPrev: null,
  };
  showStatsBanner: boolean = false;

  constructor(
    // private auth: AuthService,
    private store: Store<AppState>,
    // private messageService: MessagesService,
    public trans: TranslateService,
    private seo: SeoService,
    private searchService: SearchService,
    private router: Router,
    private apps: AppsService,
  ) {
    this.seo.setMetaTags(new HomePageSeo(trans), environment.domain + '/assets/images/og-image.jpeg');

    // this.showStatsBanner = this.isEvery3rdDay();
  }

  // isEvery3rdDay() {
  //   const referenceDate = new Date('2024-01-18');
  //   const currentDate = new Date();
  //   const daysDifference = Math.floor((currentDate.getTime()  - referenceDate.getTime() ) / (24 * 60 * 60 * 1000));

  //   return daysDifference % 3 === 0;
  // }

  // TODO : fix search skeleton: current is ugly and not working as expected, impacting by 1 lighthouse point
  ngOnInit() {
    this.getStats();
    this.searchService.ping().subscribe();
    // this.auth.isAuthenticated().subscribe((x) => {
    //   this.isAuth = x;

    //   if (x) this.runUserAuthedActions();
    // });

    this.featureValuation$ = this.store.select(
      createSelector(
        (state: AppState) => state.settings.FEATURE_VALUATION,
        (FEATURE_VALUATION) => FEATURE_VALUATION,
      ),
    );
    this.featureStats$ = this.store.select(
      createSelector(
        (state: AppState) => state.settings.FEATURE_STATS,
        (FEATURE_STATS) => FEATURE_STATS,
      ),
    );
    this.flagFeaturedProps$ = this.store.select((state) => state.settings.FLAG_FEATURED_PROPS);
    this.locations = this.mapLocations(this.preLoadLocations);
  }

  // private runUserAuthedActions() {
  //   this.store
  //     .select(
  //       createSelector(
  //         (state: AppState) => state.userAccount,
  //         (userAccount: UserAccount) => userAccount.userData
  //       )
  //     )
  //     .subscribe((user) => (this.user = user));

  //   this.messageService.countMessages().subscribe((x) => {
  //     this.countMessages = x.reduce((acc, item) => acc + item.num, 0);
  //   });
  // }

  private getStats() {
    this.apps.getStats().subscribe((stats: StatsInterface) => {
      this.statsValues = stats;
    });
  }

  private mapLocations(locations) {
    return locations.map((item) => {
      item.preLabel = '';
      item.postLabel = item.count > 0 ? ` (${item.count})` : '';
      if (item.type === LOCATION_COUNTY) {
        item.preLabel = this.trans._('County') + ' ';
      }
      return item;
    });
  }

  loadLocations() {
    if (!this.locationLoaded) {
      this.searchService.getLocations({ has_property: true, contractType: this.searchType }).subscribe((l) => {
        this.locationLoaded = true;
        this.locations = this.mapLocations(l);
      });
    }
  }

  locationChange(locations: Array<any>) {
    this.doSearch({
      locations,
      contractType: this.searchType,
    });
  }

  setSearchContractType(type) {
    this.locationLoaded = false;
    this.searchType = type;

    this.loadLocations();
  }

  searchByType(category) {
    this.doSearch({
      category: category,
      contractType: this.searchType,
    });
  }

  private doSearch(searchParams: SearchParamsInterface) {
    const searchUrl = SearchParamsParser.buildSearchUrl(searchParams);
    this.router.navigateByUrl(searchUrl);
  }
}
