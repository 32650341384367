import {
  SEARCH_START,
  SEARCH_DONE,
  SEARCH_FAIL,
  SEARCH_CLEAR,
  GET_PROPERTY_BY_DATA,
  FAVORITES_ADD,
  FAVORITES_REMOVE,
  FAVORITES_CLEAR,
  FAVORITES_RESTORE,
} from '@state/actions/search.actions';
import AppAction from '@state/actions';
import { SearchState } from '@state/app.state';

export function searchReducer(state: SearchState, action: AppAction) {
  switch (action.type) {
    case SEARCH_START:
      return {
        ...state,
        results: {
          properties: [],
          total: 0,
          agencies: {},
          geojson: {}
        },
        params: action.payload,
        searching: true
      };
    case SEARCH_DONE:
      return {
        ...state,
        searching: false,
        results: action.payload
      };
    case SEARCH_FAIL:
    case SEARCH_CLEAR:
      return {
        ...state,
        searching: false,
        results: {
          properties: [],
          total: 0,
          agencies: {},
          geojson: {}
        },
        error: action.payload
      };
    case GET_PROPERTY_BY_DATA:
      const { property, agency } = action.payload;
      return {
        ...state,
        openProperty: {
          id: property.id,
          property,
          agency
        }
      };
    case FAVORITES_ADD: {
      const favoriteProperties = Object.assign({}, state.favoriteProperties);
      favoriteProperties[action.payload] = action.payload;
      return {
        ...state,
        favoriteProperties
      };
    }
    case FAVORITES_REMOVE: {
      const favoriteProperties = Object.assign({}, state.favoriteProperties);
      delete favoriteProperties[action.payload];
      return {
        ...state,
        favoriteProperties
      };
    }
    case FAVORITES_CLEAR: {
      return {
        ...state,
        favoriteProperties: {},
        trash: {
          favoriteProperties: { ...state.favoriteProperties }
        }
      };
    }
    case FAVORITES_RESTORE: {
      const favoriteProperties = Object.assign({}, state.trash.favoriteProperties || {});
      return {
        ...state,
        favoriteProperties
      };
    }
    default:
      return state;
  }
}
