import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@services/translate.service';
import { buildSetLanguage } from '@state/actions/settings.actions';
import AppState from '@state/app.state';
import { NgClass } from '@angular/common';

@Component({
    selector: '[langSelector]',
    templateUrl: './lang-selector.component.html',
    styleUrls: ['./lang-selector.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class LangSelectorComponent {
  constructor(public trans: TranslateService, private store: Store<AppState>) {
  }
  setLanguage(lang) {
    this.store.dispatch(buildSetLanguage(lang));
  }
}
