import { Component } from '@angular/core';

@Component({
    selector: 'search-svg',
    template: `<svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 60 60"
    style="enable-background:new 0 0 60 60;"
    xml:space="preserve"
    class="search-svg"
  >
    <g>
      <g transform="translate(-220.000000, -154.000000)">
        <g transform="translate(0.000000, -1.000000)">
          <g transform="translate(0.000000, 128.000000)">
            <g>
              <g transform="translate(220.000000, 27.000000)">
                <g>
                  <path
                    class="empty-path"
                    d="M11.7,26.1c0-7.9,6.5-14.4,14.4-14.4c7.9,0,14.4,6.5,14.4,14.4c0,7.9-6.5,14.4-14.4,14.4
               C18.2,40.5,11.7,34,11.7,26.1L11.7,26.1z M50,47.9L38.5,36.4c2.3-2.8,3.7-6.4,3.7-10.3C42.2,17.2,35,10,26.1,10
               S10,17.2,10,26.1c0,8.9,7.2,16.1,16.1,16.1c3.9,0,7.5-1.4,10.3-3.7L47.9,50L50,47.9z"
                  />
                  <path
                    class="filled-path"
                    d="M50,47.9L38.5,36.4c2.3-2.8,3.7-6.4,3.7-10.3C42.2,17.2,35,10,26.1,10
                  C17.2,10,10,17.2,10,26.1c0,8.9,7.2,16.1,16.1,16.1c3.9,0,7.5-1.4,10.3-3.7L47.9,50L50,47.9z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>`,
    standalone: true,
})
export class SearchComponent {}
