import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { CreateSubscriptionLink, EndSubscriptionRequest, StartSubscription } from '@state/actions/subscription.actions';
import { filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import AppState from '@state/app.state';
import { Router } from '@angular/router';
import { ApiResponseInterface } from '@interfaces/response.interface';
import { SubscriptionService } from '@services/subscription.service';

@Injectable()
export class SubscriptionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private auth: AuthService,
    private router: Router,
    private sub: SubscriptionService,
  ) {}

  startSubscriptionEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StartSubscription.type),
        switchMap(({ packageType }) =>
          this.auth.isAuthenticated().pipe(
            tap((isAuthenticated) => {
              if (isAuthenticated) {
                this.store.dispatch(CreateSubscriptionLink({ packageType }));
              } else {
                this.router.navigate(['/account/login']);
              }
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  createSubscriptionLinkEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CreateSubscriptionLink.type),
        map(({ packageType }) => {
          return this.sub.createSubscriptionCheckoutUrl(packageType).subscribe({
            next: (json: ApiResponseInterface) => {
              window.location.href = json.result as string;
            },
            error: (error) => {
              console.log(error);
              this.store.dispatch(EndSubscriptionRequest({ status: 'error', error: error.statusText }));
            },
          });
        }),
      ),
    { dispatch: false },
  );
}
