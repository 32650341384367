import { AgencyState } from '@state/app.state';
import {
  LoadAgencyDataAction,
  SetAgencyDataAction,
  SaveAgencyStartAction,
  SaveAgencyDoneAction,
  SaveAgencyErrorAction,
  ToggleAgencyAction,
} from '@state/actions/agency.actions';
import { createReducer, on } from '@ngrx/store';
import { initialState } from '@state/app.state';


export const agencyReducer = createReducer(
  initialState.agency,
  on(LoadAgencyDataAction, (state: AgencyState) => ({ ...state,  syncing: true })),
  on(SetAgencyDataAction, (state: AgencyState, { agency }) => ({ ...state, agencyData: agency, syncing: false })),
  on(SaveAgencyStartAction, (state, { agency }) => ({ ...state, agencyData: agency, syncing: true })),
  on(SaveAgencyDoneAction, (state) => ({ ...state, syncing: false })),
  on(SaveAgencyErrorAction, (state, { error }) => ({ ...state, error, syncing: false })),
  on(ToggleAgencyAction, (state, { isEnabled }) => ({ ...state, isEnabled }))
);
