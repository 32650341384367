import { Component } from '@angular/core';

@Component({
    selector: 'realestate-svg',
    template: `<svg class="realestate-svg" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	y="0px" viewBox="0 0 285 285" style="enable-background:new 0 0 285 285;" xml:space="preserve">
	<g>
		<path
			d="M280.492,124.251L150.948,12.848c-4.861-4.175-12.035-4.175-16.896,0L4.508,124.251
			c-5.431,4.667-6.036,12.846-1.373,18.27c4.663,5.427,12.853,6.03,18.265,1.376L142.5,39.755l121.101,104.142
			c2.443,2.103,5.443,3.129,8.437,3.129c3.644,0,7.268-1.528,9.828-4.506C286.528,137.097,285.923,128.917,280.492,124.251z" />
		<polygon points="106.145,275.284 106.145,243.571 99.088,275.284 		" />
		<polygon points="178.855,275.284 185.912,275.284 178.855,243.571 		" />
		<path d="M146.73,57.917c-2.425-2.094-6.036-2.094-8.461,0l-97.623,84.208c-1.423,1.228-2.245,3.018-2.245,4.901v121.778
			c0,3.578,2.9,6.479,6.481,6.479H72.48l15.901-71.111c0.785-3.42,2.852-6.522,6.147-8.399c0.811-0.461,19.05-10.505,42.992-11.819
			l3.229,6.479h0.111l-8.752,68.108l9.587,16.743h1.607l9.587-16.743l-8.752-68.108h0.081l3.247-6.479
			c23.93,1.314,42.193,11.358,43.004,11.819c3.296,1.877,5.362,4.979,6.147,8.399l15.901,71.111h27.598
			c3.581,0,6.481-2.9,6.481-6.479V147.027c0-1.883-0.822-3.674-2.245-4.901L146.73,57.917z M142.5,181.032
			c-18.134,0-32.849-21.026-32.849-39.16s14.715-32.835,32.849-32.835c18.122,0,32.83,14.701,32.83,32.835
			S160.622,181.032,142.5,181.032z" />
	</g>
</svg>`,
    standalone: true,
})
export class RealEstateSvgComponent {}
