import AppAction from '@state/actions';
import { MESSAGE_LOAD, MESSAGE_LOADED } from '@state/actions/messages.actions';
import { MessageState } from '@state/app.state';

export function messagesReducer(state, action: AppAction): MessageState {
  switch (action.type) {
    case MESSAGE_LOAD: {
      return {
        ...state,
        syncing: true
      };
    }
    case MESSAGE_LOADED: {
      return {
        messageList: action.payload,
        syncing: false
      };
    }
    default:
      return state;
  }
}
