<header class="header">
  <nav class="main-nav nav" aria-label="Main website navigation menu" role="navigation" itemtype="https://schema.org/SiteNavigationElement">
    <ul class="nav-items nav-left">
      <li routerLinkActive="selected" [routerLinkActiveOptions]="{ exact: true }">
        <a trackClick="click_home_desk" routerLink="/" class="main-nav__link main-nav-logo" aria-label="Home page logo"> </a>
      </li>
      <li routerLinkActive="selected">
        <a trackClick="click_buy_desk" routerLink="/properties/sale" class="main-nav__link">
          <span>{{ 'Buy' | _: trans.lang }}</span>
        </a>
      </li>
      <li routerLinkActive="selected">
        <a trackClick="click_rent_desk" routerLink="/properties/rent" class="main-nav__link">
          <span>{{ 'Rent' | _: trans.lang }}</span>
        </a>
      </li>
      <li routerLinkActive="selected">
        <a trackClick="click_map_desk" routerLink="/map" class="main-nav__link">
          <span>{{ 'Map' | _: trans.lang }}</span>
        </a>
      </li>
      <li routerLinkActive="selected">
        <a trackClick="click_stats_desk" routerLink="/report/stats" class="main-nav__link">
          <span>{{ 'Stats' | _: trans.lang }}</span>
        </a>
      </li>
      <li routerLinkActive="selected">
        <a trackClick="click_stats_desk" routerLink="/subscribe" class="main-nav__link">
          <span>{{ 'Subscriptions' | _: trans.lang }}</span>
        </a>
      </li>
    </ul>

    <ul class="nav-items nav-right">

      @if(isAuth$ | async; as isAuth) {
        <li routerLinkActive="selected">
          <a trackClick="click_create_prop_desk" routerLink="/account/my-properties" class="main-nav__link" data-testid="account-link">
            <span>{{ 'My Properties' | _: trans.lang }}</span>
          </a>
        </li>
        <li routerLinkActive="selected">
          <a trackClick="click_mydata_desk" routerLink="/account/my-data" class="main-nav__link">
            <span>{{ 'Account' | _: trans.lang }}</span>
          </a>
        </li>
        @if(isAdmin) {
          <li routerLinkActive="selected">
            <a routerLink="/admin" class="main-nav__link">
              <span>{{ 'Administration' | _: trans.lang }}</span>
            </a>
          </li>
        }
      } @else {
        <li routerLinkActive="selected">
          <a trackClick="click_create_prop_desk" routerLink="/account/create-property" class="main-nav__link" data-testid="account-link">
            <span>{{ 'Advertise property' | _: trans.lang }}</span>
          </a>
        </li>
        <li routerLinkActive="selected">
          <a trackClick="click_mydata_desk" routerLink="/account" class="main-nav__link">
            <span class="button button--primary call-to-action">{{ 'Sign in' | _: trans.lang }}</span>
          </a>
        </li>
      }

      <li>

      </li>
    </ul>
  </nav>
</header>