import { Component } from '@angular/core';

@Component({
    selector: 'list-svg',
    template: `<svg class="list-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 502 502" style="enable-background:new 0 0 502 502;" xml:space="preserve">
    <g>
      <g>
        <g>
          <path d="M159,171h303c22.056,0,40-17.944,40-40c0-22.056-17.944-40-40-40H159c-22.056,0-40,17.944-40,40
          C119,153.056,136.944,171,159,171z M159,111h303c11.028,0,20,8.972,20,20s-8.972,20-20,20H159c-11.028,0-20-8.972-20-20
          S147.972,111,159,111z" />
          <path d="M462,211H159c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40h303c22.056,0,40-17.944,40-40
          C502,228.944,484.056,211,462,211z M462,271H159c-11.028,0-20-8.972-20-20s8.972-20,20-20h303c11.028,0,20,8.972,20,20
          S473.028,271,462,271z" />
          <path d="M462,331H159c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40h303c22.056,0,40-17.944,40-40
          C502,348.944,484.056,331,462,331z M462,391H159c-11.028,0-20-8.972-20-20s8.972-20,20-20h303c11.028,0,20,8.972,20,20
          S473.028,391,462,391z" />
          <path d="M49,82c-27.019,0-49,21.981-49,49s21.981,49,49,49s49-21.981,49-49S76.019,82,49,82z M49,160c-15.991,0-29-13.009-29-29
          c0-15.991,13.009-29,29-29s29,13.009,29,29C78,146.991,64.991,160,49,160z" />
          <path d="M49,202c-27.019,0-49,21.981-49,49c0,27.019,21.981,49,49,49s49-21.981,49-49S76.019,202,49,202z M49,280
          c-15.991,0-29-13.009-29-29c0-15.991,13.009-29,29-29s29,13.009,29,29C78,266.991,64.991,280,49,280z" />
          <path d="M49,322c-27.019,0-49,21.981-49,49c0,27.019,21.981,49,49,49s49-21.981,49-49C98,343.981,76.019,322,49,322z M49,400
          c-15.991,0-29-13.009-29-29c0-15.991,13.009-29,29-29s29,13.009,29,29C78,386.991,64.991,400,49,400z" />
          <path
            d="M216,139h171c5.523,0,10-4.477,10-10s-4.477-10-10-10H216c-5.523,0-10,4.477-10,10S210.477,139,216,139z" />
          <path
            d="M158,139h18c5.523,0,10-4.477,10-10s-4.477-10-10-10h-18c-5.523,0-10,4.477-10,10S152.477,139,158,139z" />
        </g>
      </g>
    </g>
  </svg>`,
    standalone: true,
})
export class ListSvgComponent {}
