import { authReducer } from '@state/reducers/auth.reducer';
import { userPropertiesReducer } from '@state/reducers/user-properties.reducer';
import { userAccountReducer } from '@state/reducers/user-account.reducer';
import { uploaderReducer } from '@state/reducers/uploader.reducer';
import { searchReducer } from '@state/reducers/search.reducer';
import { agencyReducer } from './agency.reducer';
import { messagesReducer } from './messages.reducer';
import { settingsReducer } from './settings.reducer';
import { adminReducer } from './admin.reducer';
import { paymentReducer } from './payment.reducer';
import { subscriptionReducer } from './subscription.reducer';

export const reducers = {
  auth: authReducer,
  agency: agencyReducer,
  userProperties: userPropertiesReducer,
  userAccount: userAccountReducer,
  uploader: uploaderReducer,
  search: searchReducer,
  messages: messagesReducer,
  settings: settingsReducer,
  admin: adminReducer,
  payment: paymentReducer,
  subscription: subscriptionReducer
};
