import { Component } from '@angular/core';
import { environment } from 'environments/environment';
import { TranslateService } from '@services/translate.service';
import { TranslatePipe } from 'app/helpers/translate.pipe';
import { LangSelectorComponent } from '@components/lang-selector/lang-selector.component';
import { RouterModule } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [RouterModule, TranslatePipe, LangSelectorComponent],
})
export class FooterComponent  {
  version = environment.appVersion;
  constructor(public trans: TranslateService) {}


}
