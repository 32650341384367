<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="500" height="200" viewBox="0 0 500 200" style="display: none">
  <!--
    By wrapping our reference graphic in a <defs> tag we will make sure it does not get rendered here, only when it's referenced
-->
  <defs>
    <g id="favorite-svg">
      <path
        d="M13 4.419c-2.826-5.695-11.999-4.064-11.999 3.27 0 7.27 9.903 10.938 11.999 15.311 2.096-4.373 12-8.041 12-15.311 0-7.327-9.17-8.972-12-3.27z"
      />
    </g>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" id="bedroom-svg">
      <g transform="translate(-503.000000, -2123.000000)" fill="#BBBBBB">
        <g transform="translate(0.000000, 1628.000000)">
          <g transform="translate(503.000000, 495.000000)">
            <g>
              <path
                d="M50.0737174,33.2795885 C50.0737174,34.7990385 48.9189109,36.0356174 47.4982775,36.0356174 C45.9784405,36.0356174 45.1838489,34.6497454 45.1838489,33.2795885 L45.1838489,28.0081677 L7.11279867,28.0081677 L7.11279867,33.2795885 C7.11279867,34.7990385 5.94932395,36.0356174 4.51905909,36.0356174 C3.08975736,36.0356174 1.92628264,34.7990385 1.92628264,33.2795885 L1.92628264,4.72041149 C1.92628264,3.20096151 3.08975736,1.96438264 4.51905909,1.96438264 C5.94932395,1.96438264 7.11279867,3.20096151 7.11279867,4.72041149 L7.11279867,11.402259 L7.11279867,19.6398976 L7.11279867,20.6279821 L23.5969624,20.6279821 L23.8483423,20.0396495 C24.4291165,18.6852077 25.7418781,16.6825196 26.7204297,16.6825196 L47.4780515,16.6825196 C48.8370439,16.7021634 50.0737174,17.9652614 50.0737174,19.3344361 L50.0737174,33.2795885 Z M14.0358585,12.3844504 C19.341804,12.3844504 20.5524727,16.6697511 20.8288942,18.6577063 L9.03908131,18.6577063 L9.03908131,12.3844504 L14.0358585,12.3844504 Z M47.495388,14.7181369 L26.7204297,14.7181369 C24.8846823,14.7181369 23.4688646,16.6520716 22.6935358,18.0300861 C22.1589924,14.7652821 19.9476199,10.4200677 14.0358585,10.4200677 L9.03908131,10.4200677 L9.03908131,4.72041149 C9.03908131,2.11760449 7.01070569,0 4.51905909,0 C2.02741248,0 0,2.11760449 0,4.72041149 L0,33.2795885 C0,35.8814133 2.02741248,38 4.51905909,38 C7.01070569,38 9.03908131,35.8814133 9.03908131,33.2795885 L9.03908131,29.9725503 L43.2575662,29.9725503 L43.2575662,33.2795885 C43.2575662,35.9698105 45.0798296,38 47.4982775,38 C49.9802926,38 52,35.8814133 52,33.2795885 L52,19.3344361 C52,16.8622606 49.9369513,14.7485849 47.495388,14.7181369 L47.495388,14.7181369 Z"
                id="Fill-1"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>

    <g transform="translate(-503.000000, -2182.000000)" fill="#BBBBBB" id="bathroom-svg">
      <g transform="translate(0.000000, 1628.000000)">
        <g transform="translate(503.000000, 495.000000)">
          <g transform="translate(0.000000, 59.000000)">
            <g transform="translate(0.000000, 12.404806)">
              <path
                d="M50.064637,3.4374 C50.064637,8.64884444 46.3447111,13.2227889 41.2188593,14.3145667 L40.4600444,14.4760667 L40.4600444,17.3490667 C40.4600444,18.1981222 39.7724889,18.8885111 38.9270074,18.8885111 C38.0815259,18.8885111 37.3939704,18.1981222 37.3939704,17.3490667 L37.3939704,14.5620111 L14.5496,14.5620111 L14.5496,17.3490667 C14.5496,18.1981222 13.8610815,18.8885111 13.0156,18.8885111 C12.1701185,18.8885111 11.4835259,18.1981222 11.4835259,17.3490667 L11.4835259,14.4675667 L10.728563,14.3032333 C5.62774815,13.1944556 1.92611852,8.62523333 1.92611852,3.4374 L1.92611852,2.00467778 L50.064637,2.00467778 L50.064637,3.4374 Z M0.000192592592,0.115788889 L0.000192592592,3.4374 C0.000192592592,9.24478889 3.9753037,14.3929556 9.5576,15.9597889 L9.5576,17.3490667 C9.5576,19.2398444 11.1089333,20.7774 13.0156,20.7774 C14.9241926,20.7774 16.4755259,19.2398444 16.4755259,17.3490667 L16.4755259,16.4509 L35.4680444,16.4509 L35.4680444,17.3490667 C35.4680444,19.2398444 37.0193778,20.7774 38.9270074,20.7774 C40.834637,20.7774 42.3859704,19.2398444 42.3859704,17.3490667 L42.3859704,15.9749 C47.9961926,14.4241222 51.990563,9.27123333 51.990563,3.4374 L51.990563,0.115788889 L0.000192592592,0.115788889 Z"
                id="bbb"
              ></path>
            </g>
            <path
              d="M50.1357037,8.55090556 L6.84762963,8.55090556 L6.84762963,4.36512778 C6.84762963,2.89273889 8.05133333,1.69518333 9.53044444,1.69518333 L9.56318519,1.69518333 C11.0432593,1.69518333 12.246,2.89273889 12.246,4.36512778 C12.246,4.83357222 12.6282963,5.21323889 13.0982222,5.21323889 C13.5691111,5.21323889 13.9494815,4.83357222 13.9494815,4.36512778 C13.9494815,1.95773889 11.9821481,-9.44444446e-05 9.56318519,-9.44444446e-05 L9.53044444,-9.44444446e-05 C7.11244444,-9.44444446e-05 5.14414815,1.95773889 5.14414815,4.36512778 L5.14414815,8.55090556 L1.97214815,8.55090556 C0.748222222,8.55090556 0.0385185185,9.37351667 0.0385185185,10.6249056 L51.9595556,10.6249056 C51.9595556,9.33385 51.3461481,8.55090556 50.1357037,8.55090556"
              id="Fill-4"
            ></path>
          </g>
        </g>
      </g>
    </g>

    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#999999" stroke="none" id="square-m-svg">
      <path
        d="M3937 4380 c-57 -15 -127 -53 -180 -100 l-48 -43 32 -33 32 -34 49
46 c60 56 119 83 181 84 160 1 239 -156 173 -342 -45 -128 -165 -282 -371
-478 -50 -47 -90 -88 -89 -92 1 -5 2 -24 3 -43 l1 -35 330 0 330 0 0 51 0 50
-239 -3 -239 -3 117 120 c220 226 311 406 299 586 -9 124 -75 218 -180 255
-55 19 -152 26 -201 14z"
      />
      <path
        d="M780 4339 c-14 -5 -33 -20 -42 -32 -16 -21 -18 -129 -18 -1775 l0
-1754 29 -29 29 -29 1749 0 c1925 0 1774 -5 1808 60 22 42 23 1742 1 1795 -17
41 -42 55 -94 55 -33 0 -45 -6 -66 -31 l-26 -31 0 -824 0 -824 -1615 0 -1615
0 0 1615 0 1615 826 0 826 0 29 29 c40 40 40 89 0 133 l-29 33 -884 2 c-563 1
-892 -2 -908 -8z"
      />
      <path
        d="M2070 3303 c-88 -17 -222 -97 -327 -197 -39 -36 -73 -66 -75 -66 -3
0 -8 37 -12 83 -4 45 -9 97 -13 115 l-5 32 -101 0 -100 0 6 -307 c4 -170 7
-532 7 -805 l0 -498 120 0 120 0 0 584 0 584 91 86 c66 62 115 98 173 127 80
40 82 41 172 37 81 -3 98 -7 140 -34 33 -20 60 -48 81 -83 61 -101 63 -130 63
-740 l0 -551 120 0 120 0 0 578 0 577 45 52 c63 72 161 148 239 184 53 25 79
31 134 31 157 0 241 -66 290 -227 13 -42 16 -145 19 -622 l4 -573 120 0 120 0
-4 598 c-3 512 -6 607 -20 666 -60 249 -214 376 -457 376 -151 0 -284 -66
-445 -221 -47 -45 -86 -80 -88 -78 -1 2 -16 33 -32 68 -71 154 -209 233 -400
230 -44 -1 -91 -4 -105 -6z"
      />
    </g>

    <g transform="translate(-814.59595,-274.38623)" id="svg-map-pin">
      <g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">
        <path
          style="fill: currentColor"
          d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z"
        />
        <circle
          r="3.0355"
          cy="288.25278"
          cx="823.03064"
          id="path3049"
          style="display: inline; opacity: 1; fill: white; fill-opacity: 1; stroke-width: 0"
        />
      </g>
    </g>


    <g id="svg-stat-icon">
      <path style="fill: none" d="M0 0h24v24H0V0z"/><path style="fill: currentColor" d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6h-6z"/>
    </g>

    <g id="svg-whatsapp-icon">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M307.546 52.5655C273.709 18.685 228.706 0.0171895 180.756 0C81.951 0 1.53846 80.404 1.50408 179.235C1.48689 210.829 9.74646 241.667 25.4319 268.844L0 361.736L95.0236 336.811C121.203 351.096 150.683 358.616 180.679 358.625H180.756C279.544 358.625 359.966 278.212 360 179.381C360.017 131.483 341.392 86.4547 307.546 52.5741V52.5655ZM180.756 328.354H180.696C153.966 328.346 127.744 321.16 104.865 307.589L99.4242 304.358L43.034 319.149L58.0834 264.168L54.5423 258.53C39.6304 234.809 31.749 207.391 31.7662 179.244C31.8006 97.1036 98.6334 30.2707 180.817 30.2707C220.61 30.2879 258.015 45.8015 286.145 73.9665C314.276 102.123 329.755 139.562 329.738 179.364C329.703 261.513 262.871 328.346 180.756 328.346V328.354ZM262.475 216.777C257.997 214.534 235.978 203.704 231.869 202.209C227.761 200.713 224.779 199.966 221.796 204.452C218.814 208.939 210.228 219.029 207.615 222.011C205.002 225.002 202.389 225.372 197.911 223.128C193.434 220.885 179.003 216.158 161.891 200.902C148.578 189.024 139.587 174.362 136.975 169.875C134.362 165.389 136.7 162.965 138.934 160.739C140.945 158.728 143.412 155.505 145.655 152.892C147.899 150.279 148.638 148.406 150.133 145.423C151.629 142.432 150.881 139.82 149.764 137.576C148.646 135.333 139.691 113.287 135.952 104.323C132.316 95.5909 128.621 96.777 125.879 96.6309C123.266 96.5019 120.284 96.4762 117.293 96.4762C114.302 96.4762 109.454 97.5935 105.346 102.08C101.238 106.566 89.6691 117.404 89.6691 139.441C89.6691 161.478 105.716 182.785 107.959 185.776C110.202 188.767 139.544 234.001 184.469 253.408C195.153 258.023 203.498 260.782 210.004 262.845C220.731 266.257 230.494 265.776 238.212 264.624C246.816 263.335 264.71 253.786 268.44 243.326C272.17 232.866 272.17 223.893 271.053 222.028C269.936 220.163 266.945 219.037 262.467 216.794L262.475 216.777Z" fill="#25D366" />
    </g>

    <g id="svg-tick-icon">
      <path style="fill: currentColor" d="M19.69,5.23L8.96,15.96l-4.23-4.23L2.96,13.5l6,6L21.46,7L19.69,5.23z"></path>
    </g>

    <g id="receipt-icon">
      <path style="fill: currentColor" d="M120-99v-762q0-5.25 4.5-7.125T133-866l36 36q4.636 5 10.818 5Q186-825 191-830l38-38q4.636-5 10.818-5Q246-873 251-868l38 38q4.636 5 10.818 5Q306-825 311-830l38-38q4.636-5 10.818-5Q366-873 371-868l38 38q4.636 5 10.818 5Q426-825 431-830l38-38q4.636-5 10.818-5Q486-873 491-868l38 38q4.636 5 10.818 5Q546-825 551-830l38-38q4.636-5 10.818-5Q606-873 611-868l38 38q4.636 5 10.818 5Q666-825 671-830l38-38q4.636-5 10.818-5Q726-873 731-868l38 38q4.636 5 10.818 5Q786-825 791-830l36-36q4-4 8.5-2.125T840-861v762q0 5.25-4.5 7.125T827-94l-36-36q-4.636-5-10.818-5Q774-135 769-130l-38 38q-4.636 5-10.818 5Q714-87 709-92l-38-38q-4.636-5-10.818-5Q654-135 649-130l-38 38q-4.636 5-10.818 5Q594-87 589-92l-38-38q-4.636-5-10.818-5Q534-135 529-130l-38 38q-4.636 5-10.818 5Q474-87 469-92l-38-38q-4.636-5-10.818-5Q414-135 409-130l-38 38q-4.636 5-10.818 5Q354-87 349-92l-38-38q-4.636-5-10.818-5Q294-135 289-130l-38 38q-4.636 5-10.818 5Q234-87 229-92l-38-38q-4.636-5-10.818-5Q174-135 169-130l-36 36q-4 4-8.5 2.125T120-99Zm147-197h430q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T697-356H267q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T267-296Zm0-154h430q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T697-510H267q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T267-450Zm0-155h430q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T697-665H267q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T267-605Zm-87 423h600v-596H180v596Zm0-596v596-596Z"/>
    </g>

    <g id="preview-icon">
      <path d="M218.461-160q-23.718 0-41.089-17.372Q160-194.743 160-218.461v-523.078q0-23.718 17.372-41.089Q194.743-800 218.461-800h523.078q23.718 0 41.089 17.372Q800-765.257 800-741.539v523.078q0 23.718-17.372 41.089Q765.257-160 741.539-160H218.461Zm0-33.846h523.078q10.769 0 17.692-6.923t6.923-17.692v-467.693H193.846v467.693q0 10.769 6.923 17.692t17.692 6.923Zm261.628-127.949q-64.346 0-116.051-32.703-51.705-32.704-78.397-85.523 26.692-52.819 78.309-85.758 51.616-32.939 115.961-32.939t116.051 32.96q51.705 32.96 78.91 85.859Q647.667-387 596.05-354.397q-51.616 32.602-115.961 32.602Zm.022-32.307q47.581 0 89.281-22.609T639.795-440q-28.529-40.618-70.366-63.514T479.95-526.41q-47.642 0-89.429 22.896T320.205-440q28.752 40.68 70.539 63.289 41.787 22.609 89.367 22.609ZM480-440Zm.259 41.538q16.946 0 29.113-12.426 12.166-12.426 12.166-29.371 0-16.946-12.426-29.113-12.426-12.166-29.371-12.166-16.946 0-29.113 12.426-12.166 12.426-12.166 29.371 0 16.946 12.426 29.113 12.426 12.166 29.371 12.166Z"/>
    </g>
    <g id="stats-icon">
      <path fill="none" d="M0 0h24v24H0V0z"/><path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6h-6z"/>
    </g>

    <g id="filter-icon">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 6h2.563a2 2 0 0 0 3.874 0H14.5a.5.5 0 0 0 0-1H7.937a2 2 0 0 0-3.874 0H1.5a.5.5 0 0 0 0 1ZM6 4.5a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM1.5 10a.5.5 0 0 0 0 1h6.563a2 2 0 0 0 3.874 0H14.5a.5.5 0 0 0 0-1h-2.563a2 2 0 0 0-3.874 0H1.5Zm9.5.5a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z" fill="currentColor"></path>
    </g>
    <g id="warning-icon">
      <path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z"/>
    </g>

    <g id="near-pin-icon">
        <path fill="none" d="M0 0h60v60H0z"/>
        <path style="fill: currentColor" d="M30 25.9c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4M30 6c-8.8 0-16 7.2-16 16 0 2.9.8 5.6 2.1 8L30 54l13.9-24c1.4-2.4 2.1-5.1 2.1-8 0-8.9-7.2-16-16-16m0 21.5c3.1 0 5.6-2.5 5.6-5.6s-2.5-5.6-5.6-5.6-5.6 2.5-5.6 5.6c0 3.1 2.5 5.6 5.6 5.6m0-19.9c7.9 0 14.4 6.5 14.4 14.4 0 2.5-.7 5-1.9 7.2L30 50.7 17.5 29.1c-1.3-2.2-1.9-4.7-1.9-7.2C15.6 14 22.1 7.6 30 7.6"/>
    </g>
  </defs>
</svg>
