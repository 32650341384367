import { Component, OnInit } from '@angular/core';
import { Store, select, createSelector } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import AppState from '@state/app.state';
import { AuthService } from '@services/auth.service';
import { environment } from 'environments/environment';
import { getStateToStore, getTimezone, uuidv4 } from './helpers';
import { buildStartLoadUserData, buildUserFingerPrint } from '@state/actions/user-account.actions';
import { TranslateService } from '@services/translate.service';
import { Observable, of } from 'rxjs';
import { buildGetSettings } from '@state/actions/settings.actions';
import { SvgContainerComponent } from './svg/svg-container.component';
import { HeaderMobileComponent } from '@components/header-mobile/header-mobile.component';
import { HeaderDesktopComponent } from '@components/header-desktop/header-desktop.component';
import { HamburgerMenuComponent } from '@components/hamburger-menu/hamburger-menu.component';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '@components/footer/footer.component';
import { LoaderService } from '@services/loader.service';

declare const global: any;

@Component({
  selector: 'prona-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    RouterOutlet,
    SvgContainerComponent,
    HeaderMobileComponent,
    HeaderDesktopComponent,
    HamburgerMenuComponent,
    FooterComponent
  ],
})
export class AppComponent implements OnInit {
  offlineMode$: Observable<boolean> = of(false);
  debugData = null;

  constructor(
    private store: Store<AppState>,
    private auth: AuthService,
    public trans: TranslateService,
    public loader: LoaderService
  ) {
    const fp = this.getFingerPrint();
    this.stateSaveMonitor();
    this.settingsMonitor();
    this.auth.getSettings(fp).subscribe((setts) => {
      this.store.dispatch(buildGetSettings(setts));
    });

    this.auth.isAuthenticated().subscribe((isAuthed) => {
      isAuthed && this.store.dispatch(buildStartLoadUserData());
    });

    this.debugData = this.getDebugData();
  }

  ngOnInit() {
    // try {
    //   document.getElementById('splash-screen').style.display = 'none';
    // } catch (e) {}
  }

  private stateSaveMonitor() {
    this.store
      .pipe(
        select(getStateToStore()),
        filter((val) => val !== undefined)
      )
      .subscribe((state) => {
        this.storeStateInLocationStorage(state);
      });
  }

  private settingsMonitor() {
    this.offlineMode$ = this.store.select(
      createSelector(
        (state: AppState) => state.settings.OFFLINE_MODE,
        (OFFLINE_MODE) => OFFLINE_MODE
      )
    );
  }


  private getFingerPrint() {
    // for performance reason get the REAL FP only on user click
    let fingerprint = this.getFastFP();
    const version = environment.appVersion;
    const ua = this.getUserAgent();
    const timezone = getTimezone();
    this.store.dispatch(buildUserFingerPrint(fingerprint, timezone, ua, version));

    return fingerprint;
  }

  private getDebugData() {
    try {
      if (global) {
        return global._debug_data; // see server.ts
      }
    } catch (e) {}

    return null;
  }

  private getUserAgent() {
    try {
      if (navigator) {
        return navigator.userAgent;
      }
    } catch (e) {}

    return 'no_user_agent';
  }

  private getFastFP() {
    try {
      let fastFP = localStorage.getItem('fastFP');
      if (!fastFP) {
        fastFP = uuidv4();
        localStorage.setItem('fastFP', fastFP);
      }

      return fastFP;
    } catch (e) {
    }

    try {
      if (global._forward_headers && global._forward_headers['X-Finger']) {
        console.log('using server fp', global._forward_headers['X-Finger']);
        return global._forward_headers['X-Finger'];
      }
    } catch (error) {
      
    }

    return 'no_fingerprint';
  }

  private storeStateInLocationStorage(state) {
    try {
      localStorage && localStorage.setItem('AppState', JSON.stringify(state));
    } catch (e) {}
  }
}
