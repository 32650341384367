import { Component, OnInit } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { TranslateService } from '@services/translate.service';
import { HomeSvgComponent } from 'app/svg/home-svg.component';
import { MapIconComponent } from 'app/svg/map-icon.component';
import { SearchComponent } from 'app/svg/search.component';
import { UserSvgComponent } from 'app/svg/user-svg.component';
@Component({
  selector: 'header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
  standalone: true,
  imports: [RouterModule, HomeSvgComponent, SearchComponent, MapIconComponent, UserSvgComponent],
})
export class HeaderMobileComponent implements OnInit {
  constructor(public trans: TranslateService) {}

  ngOnInit(): void {}
}
