import AppAction from '@state/actions';
import { AdminState } from '@state/app.state';
import { ADMIN_GET_USERS_START, ADMIN_GET_USERS_SUCCESS, ADMIN_GET_USERS_FAILED } from '@state/actions/admin.actions';

export function adminReducer(state: AdminState, action: AppAction) {
  switch (action.type) {
    case ADMIN_GET_USERS_START:
      return { ...state, syncing: true };
    case ADMIN_GET_USERS_SUCCESS:
      return { ...state, users: action.payload, syncing: false };
    case ADMIN_GET_USERS_FAILED:
      return { ...state, syncing: false, error: action.payload };
    default:
      return state;
  }
}
