import { Pipe, PipeTransform } from '@angular/core';
import { cleanAccents } from '.';

@Pipe({
    name: 'cleanAccent',
    standalone: true,
})
export class CleanAccentPipe implements PipeTransform {
  transform(value: string): string {
    return cleanAccents(value);
  }
}
