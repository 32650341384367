import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Store, createSelector } from '@ngrx/store';
import AppState, { AuthState, UserFingerprint } from '@state/app.state';
import { switchMap, first, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthLogoutAction } from '@state/actions/auth.actions';
import { environment } from 'environments/environment';
import { getTimezone } from 'app/helpers';

declare const global: any;
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersFromSSR = {};
    try {
      Object.assign(headersFromSSR, {
        ...global._forward_headers
      });
    } catch (e) {}
    
    try {
      if (location) {
        Object.assign(headersFromSSR, {
          'X-Location': location.href
        });
      }
    } catch (e) {}

    return this.store
      .select(
        createSelector(
          (state: AppState) => state.auth,
          (state: AppState) => state.userAccount.fingerprint,
          (state: AppState) => state.settings.language,

          (auth: AuthState, fingerprint: UserFingerprint, language: string) => {
            return { token: auth.token, fingerprint: fingerprint.fingerprint, language };
          }
        )
      )
      .pipe(
        first(),
        switchMap(({ token, fingerprint, language }) => {
          if (request.url.indexOf(environment.urlApi) !== -1) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json;charset=UTF-8',
                'X-Timezone': getTimezone(),
                'X-App-Version': environment.appVersion,
                'X-Finger': fingerprint,
                'X-Locale': language,
                'X-Req-Url': request.urlWithParams,
                ...headersFromSSR,
              },
            });
          }
          return next.handle(request);
        })
      );
  }
}

@Injectable()
export class ResponseErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private store: Store<AppState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.store.dispatch(AuthLogoutAction());
        }

        return throwError(() => error);
      })
    );
  }
}
