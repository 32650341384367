import AppAction from '@state/actions';
import { SETTINGS_GET, SETTINGS_SET_LANGUAGE } from '@state/actions/settings.actions';

export function settingsReducer(state, action: AppAction) {
  switch (action.type) {
    case SETTINGS_SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case SETTINGS_GET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
