import SeoInterface from '@interfaces/seo.interface';
import { TranslateService } from '@services/translate.service';

export class HomePageSeo implements SeoInterface {
  //meta tags for static pages
  private metaDescription = `Apartamente, prona, ne Tirane, Vlore, Sarande, Shqiperi, ne shitje apo me qera. Homezone.al - Faqa dedikuar pronave ne Shqiperi`;
  private metaRobots = 'index, follow';
  private trans: TranslateService;

  constructor(trans) {
    this.trans = trans;
  }

  getTitle(): string {
    return this.trans._('home.title2');
  }

  getDescription(): string {
    return this.metaDescription;
  }

  getRobots(): string {
    return this.metaRobots;
  }
}
