import { Component, Input, OnInit } from '@angular/core';
import { SearchService } from '@services/search.service';
import { TranslateService } from '@services/translate.service';
import { CONTRACT_SALE } from '@constants/enum';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslatePipe } from 'app/helpers/translate.pipe';
import { TrackClickDirective } from 'app/helpers/track-click.directive';
import { CleanAccentPipe } from 'app/helpers/clean-accent.pipe';

@Component({
  selector: 'popular-areas',
  templateUrl: './popular-areas.component.html',
  styleUrls: ['./popular-areas.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, TranslatePipe, TrackClickDirective, CleanAccentPipe],
})
export class PopularAreasComponent implements OnInit {
  @Input() contractType = CONTRACT_SALE;
  popularAreas$;
  title = '';
  subtitle = '';
  constructor(private searchService: SearchService, public trans: TranslateService) {

  }

  ngOnInit(): void {
    if (this.contractType === CONTRACT_SALE) {
      this.title = 'Property for sale by area';
      this.subtitle = 'Browser for apartment, houses and properties for sale by city areas';
    } else {
      this.title = 'Houses to rent by area';
      this.subtitle = 'Browser for apartment and houses for rent in most popular areas';
    }
    this.popularAreas$ = this.searchService.getPopularAreas(this.contractType);
  }
}
