import { Component } from '@angular/core';

@Component({
    selector: 'map-icon-svg',
    template: `<svg class="map-icon-svg" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="path1" d="M15.73 0.0569599C15.8112 0.0990399 15.8793 0.16259 15.927 0.2407C15.9746 0.31881 15.9998 0.40849 16 0.49996V10.5C16 10.5864 15.9776 10.6713 15.9351 10.7465C15.8925 10.8217 15.8311 10.8845 15.757 10.929L10.757 13.929C10.6817 13.9741 10.5958 13.9986 10.5081 14C10.4203 14.0014 10.3337 13.9796 10.257 13.937L5.747 11.431C5.67381 11.3845 5.58898 11.3596 5.50229 11.3593C5.41559 11.3589 5.33056 11.3831 5.257 11.429L5.243 11.437L0.743 13.937C0.66689 13.9793 0.58104 14.001 0.49396 13.9999C0.40688 13.9989 0.32158 13.9751 0.24652 13.931C0.17145 13.8868 0.10922 13.8238 0.0659803 13.7482C0.0227403 13.6726 3.13166e-07 13.587 3.13166e-07 13.5V3.99996C-8.96868e-05 3.91953 0.0192202 3.84026 0.0563002 3.76888C0.0933802 3.69751 0.14714 3.63613 0.213 3.58996L5.213 0.0899599C5.2971 0.0310099 5.3973 -0.000610352 5.5 -0.000610352C5.6027 -0.000610352 5.70291 0.0310099 5.787 0.0899599L10.5 3.38996L15.213 0.0899599C15.2881 0.0375799 15.376 0.00679966 15.4674 0.000979662C15.5587 -0.00484034 15.6499 0.0145299 15.731 0.0569599H15.73ZM1 4.25996V12.65L4.75 10.567C4.831 10.519 4.915 10.479 5 10.448V1.45996L1 4.25996ZM6.248 10.566L10.004 12.652L10 4.25996L6 1.45996V10.45C6.085 10.48 6.168 10.519 6.248 10.566ZM11.004 12.615L15 10.217V1.45996L11 4.25996L11.004 12.615Z" />
  </svg>
  `,
    standalone: true,
})
export class MapIconComponent {
}
