import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import AppAction from '@state/actions';
import { SearchService } from '@services/search.service';
import {
  SEARCH_START,
  buildSearchDone,
  buildSearchFail,
} from '@state/actions/search.actions';
import SearchResultsInterface from '@interfaces/search-result.interface';

@Injectable()
export class SearchEffects {
  constructor(private search: SearchService, private actions$: Actions) {}

  searchProperties$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SEARCH_START),
      switchMap((action: AppAction) => {
        return this.search.search(action.payload).pipe(
          map((results: SearchResultsInterface) => buildSearchDone(results)),
          catchError((error) => of(buildSearchFail(error)))
        );
      })
    );
  });
}
