<div class="hamburger-menu">
  <input trackClick="click_ham_btn" id="menu__toggle" type="checkbox" [checked]="this.trans.hamToggle$ | async" (click)="toggleHamMenu()"
    aria-label="Toggle left menu" />
  <label class="menu__btn" for="menu__toggle">
    <span></span>
  </label>

  <ul class="menu__box" (click)="toggleHamMenu(false)">
    <li routerLinkActive="selected">
      <a trackClick="click_home_ham" class="menu__item" routerLink="/">
        <home-svg></home-svg> <span class="item-label"> {{ 'Home' | _: trans.lang }}</span>
      </a>
    </li>
    <li routerLinkActive="selected">
      <a trackClick="click_search_ham" class="menu__item" routerLink="/search/filter">
        <search-svg></search-svg><span class="item-label">{{ 'Search' | _: trans.lang }}</span>
      </a>
    </li>
    <li routerLinkActive="selected">
      <a trackClick="click_map_ham" class="menu__item" routerLink="/map">
        <map-icon-svg></map-icon-svg><span class="item-label">{{ 'Map' | _: trans.lang }}</span>
      </a>
    </li>

    <li routerLinkActive="selected">
      <a trackClick="click_sub_ham" class="menu__item" routerLink="/subscribe">
        <div class="clip-star">★</div>
        <span class="item-label">{{ 'Subscriptions' | _: trans.lang }}</span>
      </a>
    </li>

    <li routerLinkActive="selected">
      <a trackClick="click_report_ham" class="menu__item" routerLink="/report/stats">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="stat-svg"><use xlink:href="#svg-stat-icon" x="0" y="0" /></svg>
        <span class="item-label">{{ 'Statistics' | _: trans.lang }}</span>
      </a>
    </li>

    <li routerLinkActive="selected">
      <a trackClick="click_value_ham" class="menu__item" routerLink="/report/value">
        <value-icon-svg></value-icon-svg><span class="item-label">{{ 'Evaluate property' | _: trans.lang }}</span>
      </a>
    </li>

    <ng-container *ngIf="!isAuthed">
      <li routerLinkActive="selected">
        <a trackClick="click_signin_ham" class="menu__item" routerLink="/account/my-data">
          <user-svg></user-svg>
          <span class="item-label">{{ 'Sign in' | _: trans.lang }}</span>
        </a>
      </li>
    </ng-container>

    <ng-container *ngIf="isAuthed">
      <li routerLinkActive="selected">
        <a trackClick="click_account_ham" class="menu__item" routerLink="/account/my-data">
          <user-svg></user-svg><span class="item-label">{{ 'Account' | _: trans.lang }}</span>
        </a>
      </li>
      <li routerLinkActive="selected">
        <a trackClick="click_create_property_ham" class="menu__item" routerLink="/account/create-property">
          <add-svg></add-svg><span class="item-label">{{ 'Add property' | _: trans.lang }}</span>
        </a>
      </li>
      <li routerLinkActive="selected">
        <a trackClick="click_myprops_ham" class="menu__item" routerLink="/account/my-properties">
          <list-svg></list-svg>
          <span class="item-label">{{ 'My Properties' | _: trans.lang }}</span>
        </a>
        <ul class="ham-sub-menu">
          <li>
            <a class="menu__item" routerLink="/account/my-properties" [queryParams]="{status: 'published'}">
              <span class="status-icon published"></span>
              <span class="item-label">{{ 'Published' | _: trans.lang }} ({{propsByStatus['published']}})</span>
            </a>
          </li>
          <li>
            <a class="menu__item" routerLink="/account/my-properties" [queryParams]="{status: 'pending'}">
              <span class="status-icon pending"></span>
              <span class="item-label">{{ 'Pending' | _: trans.lang }} ({{propsByStatus['pending']}})</span>
            </a>
          </li>
          <li>
            <a class="menu__item" routerLink="/account/my-properties" [queryParams]="{status: 'draft'}">
              <span class="status-icon draft"></span>
              <span class="item-label">{{ 'Draft' | _: trans.lang }} ({{propsByStatus['draft']}})</span>
            </a>
          </li>
        </ul>
      </li>
      <li routerLinkActive="selected">
        <a trackClick="click_agency_ham" class="menu__item" routerLink="/account/agency">
          <realestate-svg></realestate-svg>
          <span class="item-label">{{ 'Agency' | _: trans.lang }}</span>
        </a>
      </li>
      <li>
        <a class="menu__item" (click)="logout()" routerLink="/account">
          <logout-svg></logout-svg>
          <span class="item-label">{{ 'Logout' | _: trans.lang }}</span>
        </a>
      </li>
    </ng-container>
    <li>
      <div class="menu__item" langSelector>
      </div>
    </li>
  </ul>

  <div class="over-shadow" (click)="toggleHamMenu(false)"></div>
</div>
