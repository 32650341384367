<div class="mobile-only">
  <footer class="footer">
    <p class="small-text">
      <a class="footer__link" href="https://homezone.al">© Homezone.al</a> |
      <span langSelector></span>
    </p>
    <p class="small-text">
      <a class="footer__link" routerLink="/legal/contact">{{ 'Contact' | _: trans.lang }} </a> |
      <a class="footer__link" routerLink="/legal/about">{{ 'About' | _: trans.lang }} </a> |
      <a class="footer__link" routerLink="/legal/terms">{{ 'Terms of Use' | _: trans.lang }}</a> |
      <a class="footer__link" routerLink="/legal/privacy">{{ 'Privacy and GDPR' | _: trans.lang }}</a> |
      <a class="footer__link" routerLink="/blog">{{ 'Blog' | _: trans.lang }} </a>
    </p>
    <p class="small-text">Version {{ version }}</p>
    <p>
      <a class="social-media facebook" aria-label="Na ndiq ne facebook" href="https://www.facebook.com/homezone.al" target="_blank"></a>
      <a class="social-media instagram" aria-label="Na ndiq ne instagram" href="https://www.instagram.com/homezone.al/" target="_blank"></a>
    </p>
  </footer>
</div>

<div class="desktop-only">
  <footer class="footer footer-desktop">
    <div>
      <h5 class="header-5">© Homezone.al</h5>

      <p class="footer-box small-text">
        <a class="footer__link" routerLink="/legal/about">{{ 'About' | _: trans.lang }} </a>
        <a class="footer__link" routerLink="/legal/terms">{{ 'Terms of Use' | _: trans.lang }}</a>
        <a class="footer__link" routerLink="/legal/privacy">{{ 'Privacy and GDPR' | _: trans.lang }}</a>
      </p>
      <p class="small-text">Version {{ version }}</p>
    </div>
    <div>
      <h5 class="header-5">{{ 'Find us on social' | _: trans.lang }}</h5>
      <p class="center-flex flex-row">
        <a class="social-media facebook" aria-label="Na ndiq ne facebook" href="https://www.facebook.com/homezone.al" target="_blank"></a>
        <a class="social-media instagram" aria-label="Na ndiq ne instagram" href="https://www.instagram.com/homezone.al/" target="_blank"></a>
      </p>
    </div>
    <div>
      <h5 class="header-5">{{ 'Support' | _: trans.lang }}</h5>
      <p class="footer-box small-text">
        <a class="footer__link" routerLink="/legal/contact">{{ 'Contact' | _: trans.lang }} </a>
        <a class="footer__link" routerLink="/blog">{{ 'Blog' | _: trans.lang }} </a>
        <a class="footer__link" routerLink="/help">{{ 'Help' | _: trans.lang }} </a>        
      </p>
    </div>
    <div>
      <h5 class="header-5">{{ 'Language' | _: trans.lang }}</h5>
      <p class="footer-box small-text">
        <span langSelector></span>
      </p>
    </div>
  </footer>
</div>
