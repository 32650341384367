import { createReducer, on } from '@ngrx/store';
import { LoadPromoTypesDoneAction, LoadPromoTypesStartAction, OpenPaymentDialogAction, ResetSelectedPromoTypeAction, SetPromoTypeAction } from '@state/actions/payment.actions';
import { initialState } from '@state/app.state';

export const paymentReducer = createReducer(
  initialState.payment,
  on(LoadPromoTypesDoneAction, (state, { promoTypes }) => ({ ...state, promoTypes: promoTypes, syncing: false })),
  on(LoadPromoTypesStartAction, (state) => ({ ...state, syncing: true })),
  on(SetPromoTypeAction, (state, { promoType }) => ({ ...state, selectedPromoType: promoType })),
  on(ResetSelectedPromoTypeAction, (state) => ({ ...state, selectedPromoType: null })),
  on(OpenPaymentDialogAction, (state, { propertyId, promoType }) => ({ ...state, selectedPropertyId: propertyId, selectedPromoType: promoType }))
);
