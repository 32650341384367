import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom, mergeMap, tap } from 'rxjs/operators';
import AppState, { UserAccount } from '@state/app.state';
import AppAction from '@state/actions';
import { RegisterService } from '@services/register.service';
import { UserService } from '@services/user.service';
import {
  REGISTER_START,
  buildRegisterDone,
  buildRegisterFail,
  buildUserDataSaved,
  USER_DATA_SAVE_START,
  USER_START_LOAD_DATA,
  buildSetUserData,
  buildLoadUserDataFailed,
  USER_DATA_SAVE_FAILED,
  buildUserDataFailed,
  USER_DATA_SAVE_SUCCESS,
  USER_DATA_LOAD_FAILED,
} from '@state/actions/user-account.actions';
import UserInterface from '@interfaces/user.interface';
import { TranslateService } from '@services/translate.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ServerErrorInterface } from '@interfaces/error.interface';

@Injectable()
export class UserAccountEffects {
  constructor(
    private reg: RegisterService,
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService,
    private trans: TranslateService,
    private snackBar: MatSnackBar
  ) {}

  registerStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(REGISTER_START),
      withLatestFrom(this.store.select((state) => state.userAccount)),
      switchMap(([action, userData]: [AppAction, UserAccount]) => {
        const userForm = userData.userRegister.form;
        return this.reg.register(userForm).pipe(
          map(({ id, error, errorCode }) => (errorCode ? buildRegisterFail(error) : buildRegisterDone(id))),
          catchError((json) => of(buildRegisterFail(json.error)))
        );
      })
    )
  );

  loadUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_START_LOAD_DATA),
      switchMap(() => this.userService.getUserData()),
      map((user: UserInterface) => buildSetUserData(user)),
      catchError((respose: HttpErrorResponse) => of(buildLoadUserDataFailed(respose.error)))
    )
  );

  saveUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_DATA_SAVE_START),
      switchMap((action: AppAction) => this.userService.saveUserData(action.payload)),
      map(() => buildUserDataSaved()),
      catchError((respose: HttpErrorResponse) => of(buildUserDataFailed(respose.error)))
    )
  );

  genericSuccessToShowToUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(USER_DATA_SAVE_SUCCESS),
        tap((action: AppAction) => {
          this.snackBar.open(this.trans._('Changes have been saved'), this.trans._('OK'), {
            duration: 5000,
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  genericErrorToShowToUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(USER_DATA_SAVE_FAILED, USER_DATA_LOAD_FAILED),
        tap((action: AppAction) => {
          const errorObj: ServerErrorInterface = action.payload;
          this.snackBar.open(this.trans._(errorObj.error), this.trans._('Dismiss'), {
            duration: 5000,
          });
        })
      ),
    {
      dispatch: false,
    }
  );
}
