import { Component } from '@angular/core';

@Component({
    selector: 'value-icon-svg',
    template: `<svg class="value-icon-svg" viewBox="0.762 3.053 497.883 494.485" width="497.883" height="494.485">
  <g transform="matrix(0.917159, 0, 0, 0.974349, -71.395615, -22.530514)" style="">
    <path class="path2" d="m349.12 271.25c7.875 0 15.75 1.75 23.188 4.8125 15.75-21.438 27.562-48.125 26.25-54.25-1.75 0-7 2.1875-12.25 11.812 0 0.4375-0.4375 0.875-0.875 1.3125-6.5625 8.75-14 12.688-22.312 11.375-5.25-0.4375-10.5-3.5-14.875-8.3125-4.375 5.25-9.1875 7.875-14.875 8.3125-7.875 0.875-16.188-3.9375-21.438-13.125-4.8125-8.75-9.1875-11.812-11.812-11.812-0.875 7 10.938 35 25.812 55.562 7.875-3.5 15.312-5.6875 23.188-5.6875z"></path>
    <path class="path1" d="m618.19 245.88-58.625-50.312v-143.06c0-4.8125-3.9375-8.75-8.75-8.75h-81.375c-4.8125 0-8.75 3.9375-8.75 8.75v58.625l-90.562-77.438c-2.1875-1.75-3.9375-2.625-5.6875-3.5l-0.4375-0.4375c-0.4375-0.4375-1.75-0.875-3.0625-1.75-3.0625-1.3125-5.6875-1.3125-8.3125-1.75h-4.8125c-2.625 0-5.25 0.4375-8.3125 1.75-1.3125 0.4375-2.1875 1.3125-3.0625 1.75-1.75 0.875-3.9375 1.75-6.125 3.9375l-248.5 212.19c-2.625 2.625-3.9375 6.5625-2.625 10.062l28.875 72.188c0.875 2.625 3.5 4.8125 6.125 5.25 2.625 0.875 5.6875 0 7.875-1.75l38.938-33.25v203c0 17.938 14 32.375 31.5 32.375h315.88c17.062 0 31.5-14.438 31.5-32.375v-203l38.938 33.25c2.1875 1.75 5.25 2.625 7.875 1.75s4.8125-2.625 6.125-5.25l28.438-72.188c0.875-3.5-0.4375-7.4375-3.0625-10.062zm-269.06 237.12c-40.688 0-65.188-6.5625-79.625-20.562-15.75-15.75-15.75-38.062-15.75-61.688v-3.5c0-33.688 24.938-84.875 57.312-110.25-13.562-19.25-31.5-53.375-28-69.125 1.75-6.125 5.6875-10.062 11.812-12.25 0.4375 0 1.3125-0.4375 1.75-0.4375 6.5625-1.3125 19.25 0 30.625 20.562 1.3125 2.1875 3.5 3.9375 3.9375 4.375 1.75 0 5.25-2.625 9.1875-11.375 1.3125-3.0625 4.375-4.8125 7.875-4.8125s6.5625 1.75 7.875 4.8125c3.9375 8.75 7.4375 10.938 8.75 11.375h0.875c0.875 0 2.1875-0.4375 5.25-3.9375 14-25.812 31.5-20.125 32.375-19.688 5.6875 1.75 9.625 5.6875 11.812 11.375 5.25 15.75-14.875 49.875-27.562 68.25 34.562 24.062 59.062 76.125 59.062 112 0 45.062 0 84.875-97.562 84.875z"></path>
    <path class="path1" d="m372.75 295.75c-0.875 0-1.3125-0.4375-2.1875-0.875-7.4375-3.9375-14.875-6.125-21.438-6.125s-13.562 2.1875-20.562 7c-0.4375 0.4375-0.875 0.4375-1.3125 0.4375-28.438 18.812-56 67.812-56 100.19v3.5c0 21.438 0 38.938 10.5 49.438s32.375 15.75 66.938 15.75c80.062 0 80.062-24.5 80.062-68.688 0.4375-32.375-24.5-82.25-56-100.62zm6.125 122.94c-4.375 4.375-11.375 7-20.562 8.3125v18.812h-15.75v-18.375c-7-0.4375-12.688-0.875-16.625-2.1875-4.375-1.3125-7.875-2.625-10.5-3.9375l-1.3125-0.4375 4.8125-13.562 1.3125 0.4375c2.625 1.3125 6.125 2.625 10.938 3.9375 3.5 0.875 8.75 1.3125 15.312 1.3125 7.4375 0 13.125-1.3125 16.188-3.0625 3.0625-2.1875 4.375-4.8125 4.375-7.875 0-2.1875-0.4375-4.375-1.75-6.125s-3.0625-3.5-5.25-4.8125-4.8125-2.625-7.875-3.9375-6.5625-2.1875-9.625-3.5c-2.625-0.875-4.8125-1.75-7.4375-2.625l-2.1875-0.875c-3.5-1.75-6.5625-3.5-8.75-5.25-2.625-1.75-4.375-4.375-6.125-7.4375-1.75-2.625-2.625-6.5625-2.625-10.5 0-7.4375 2.625-13.562 7-17.938s10.938-7.4375 18.812-8.75v-17.5h15.75v16.625c3.9375 0 7.875 0.875 11.812 1.75 4.375 0.875 7.4375 1.75 9.625 2.625l1.3125 0.4375-3.9375 14-1.3125-0.4375c-2.1875-0.875-5.25-1.75-9.1875-2.625s-8.3125-1.3125-13.562-1.3125c-6.125 0-10.5 0.875-13.562 3.0625s-4.375 4.8125-4.375 8.3125c0 1.75 0.4375 3.5 1.3125 5.25 0.875 1.3125 2.1875 2.625 3.9375 3.9375 1.75 0.875 3.5 2.1875 6.5625 3.0625 1.3125 0.4375 2.625 0.875 4.375 1.75 1.3125 0.4375 2.625 0.875 4.375 1.75 4.375 1.3125 7.875 3.0625 11.812 4.375 3.5 1.75 7 3.5 10.062 5.6875 3.0625 2.625 5.6875 5.25 7 8.3125 1.75 3.0625 2.625 7 2.625 11.812 0.4375 7-2.1875 13.125-7 17.5z"></path>
  </g>
</svg>
  `,
    standalone: true,
})
export class ValueIconComponent {
}
