import {
  REGISTER_DONE,
  REGISTER_FAIL,
  REGISTER_FORM,
  REGISTER_START,
  USER_START_LOAD_DATA,
  USER_DATA_SAVE_SUCCESS,
  USER_DATA_SAVE_START,
  USER_DATA_LOAD_SUCCESS,
  USER_DATA_LOAD_FAILED,
  USER_FINGERPRINT,
  USER_DATA_SAVE_FAILED
} from '@state/actions/user-account.actions';
import AppAction from '@state/actions';
import { UserAccount } from '@state/app.state';

export function userAccountReducer(state: UserAccount, action: AppAction) {
  switch (action.type) {
    case REGISTER_FORM:
      return {
        ...state,
        userRegister: {
          form: action.payload.data,
          status: action.payload.isValid ? 'valid' : 'invalid'
        }
      };
    case REGISTER_START:
      return {
        ...state,
        userRegister: {
          ...state.userRegister,
          status: 'sending',
          sending: true
        }
      };
    case REGISTER_DONE:
      return {
        ...state,
        userRegister: {
          ...state.userRegister,
          status: 'done',
          error: null,
          sending: false
        }
      };
    case REGISTER_FAIL:
      return {
        ...state,
        userRegister: {
          ...state.userRegister,
          status: 'error',
          error: action.payload,
          sending: false
        }
      };
    case USER_START_LOAD_DATA:
      return {
        ...state,
        syncing: true
      };
    case USER_DATA_LOAD_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        syncing: false
      };
    case USER_DATA_LOAD_FAILED:
      return {
        ...state,
        syncing: false,
        error: action.payload
      };
    case USER_DATA_SAVE_START:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload
        },
        syncing: true
      };
    case USER_DATA_SAVE_SUCCESS:
      return {
        ...state,
        syncing: false
      };
      case USER_DATA_SAVE_FAILED:
        return {
          ...state,
          syncing: false,
          error: action.payload
        };
    case USER_FINGERPRINT:
      return {
        ...state,
        fingerprint: action.payload
      }
    default:
      return state;
  }
}
